import React, { useState } from "react"
import "../Card/newlistingcard.scss"
import VizSensor from "react-visibility-sensor"
import { Link, navigate } from "gatsby"
import PreLoader from "./preloader.png"
import Carousel from "react-multi-carousel"
const MeetingListingCard = props => {
  const [imgViz, setimgViz] = useState(false)
  const [imgVizdisplay, setimgVizdisplay] = useState(false)
  const url = `https://www.gofloaters.com/app/#/home/explore/bookingrequest/${props.spaceId}`
  var divStyleLoad = {
    height: "200px",
    backgroundImage: "url(" + PreLoader + ")",
    backgroundSize: "cover",
    WebkitTransition: "all", // note the capital 'W' here
    msTransition: "all", // 'ms' is the only lowercase vendor prefix
  }
  var divStyle = {
    height: "200px",
    backgroundImage: "url(" + props.listingImg + ")",
    backgroundSize: "cover",
    WebkitTransition: "all", // note the capital 'W' here
    msTransition: "all", // 'ms' is the only lowercase vendor prefix
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  var str = ""
  const HourPrice = () => {
    return (
      <span className="priceTag">
        {props.priceperhrPreDiscount ? (
          <b className="priceStrike">₹ {props.priceperhrPreDiscount} </b>
        ) : (
          ""
        )}
        <b>₹ {props.priceHr}</b>/hour
      </span>
    )
  }

  const ratingText = rating => {
    if (rating > 4.5) {
      return "Very Good"
    } else if (rating >= 3.5 && rating <= 4.5) {
      return "Good"
    } else if (rating >= 2.5 && rating <= 3.5) {
      return "Average"
    } else if (rating < 2.5) {
      return "Below Average"
    }
  }

  let SpaceSuitedFor = ""
  if (props.purposesList) {
    const lastIndex = props.purposesList.length - 1
    SpaceSuitedFor = props.purposesList.map((purpose, index) =>
      lastIndex === index ? (
        <span style={{ fontSize: "12px" }}>{purpose} </span>
      ) : (
        <span style={{ fontSize: "12px" }}>{purpose} | </span>
      )
    )
  }

  const MonthlyPrice = () => {
    return (
      <span className="priceTag">
        <b>₹ {props.priceMonth}</b>/month
      </span>
    )
  }
  const DailyPrice = () => {
    return (
      <span className="priceTag">
        {props.priceperdayPreDiscount ? (
          <b className="priceStrike">₹ {props.priceperdayPreDiscount} </b>
        ) : (
          ""
        )}
        <b>₹ {props.priceDay}</b>/day
        <br></br>
      </span>
    )
  }
  const DailyFree = () => {
    return (
      <span className="priceTag">
        Daily: NA
        <br></br>
      </span>
    )
  }
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value)
    }
    value = +value
    exp = +exp
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN
    }
    // Shift
    value = value.toString().split("e")
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)))
    // Shift back
    value = value.toString().split("e")
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
  }
  const ceil10 = (value, exp) => decimalAdjust("ceil", value, exp)
  return (
    <div
      className={"col-md-12 align listingCardMeetingSpace noPadding" + str}
      onClick={() => {
        navigate(props.Slug)
      }}
    >
      <div
        className="listingCardNew"
        title={props.gftitle + " - " + props.OriginalName}
      >
        <div className="row ">
          <div className="col-md-4 cardMobileNoPadding">
            <div className="listingimagesnew">
              <VizSensor
                partialVisibility
                onChange={isVisible => {
                  setimgViz(isVisible)
                  if (!imgVizdisplay) {
                    setimgVizdisplay(isVisible)
                  }
                }}
              >
                <div
                  style={imgVizdisplay ? divStyle : divStyleLoad}
                  className="divStyleCSS"
                >
                  {props.Rating ? (
                    <p className="cardRating">
                      <span>{props.Rating} ★</span>
                    </p>
                  ) : (
                    ""
                  )}
                  {props.online && props.meetingInstantBooking ? (
                    <div className="bookInstant">
                      <span>
                        <i class="fa fa-bolt" aria-hidden="true"></i> Book
                        Instantly
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </VizSensor>
            </div>
          </div>
          <div className="col-md-5 cardMobileNoPadding">
            <div className="listingDetailNew">
              <div className="row ">
                <div className="col-12">
                  <div className="MobileOnly" style={{ paddingBottom: "10px" }}>
                    {props.bookingcount > 10 ? (
                      <span
                        style={{
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          color: "#303030",
                          background: "#f8f8f8",
                          textWrap: "nowrap",
                          border: "0.5px solid #444",
                        }}
                      >
                        <img
                          src="https://assets.gofloaters.com/popular.png"
                          style={{ width: "15px", height: "15px" }}
                          alt="Popular"
                        />{" "}
                        Popular
                      </span>
                    ) : (
                      ""
                    )}

                    {props.bookingcount > 30 ? (
                      <span
                        style={{
                          padding: "5px 10px",
                          borderRadius: "5px",
                          marginLeft: "10px",
                          fontSize: "12px",
                          color: "#303030",
                          background: "#f8f8f8",
                          border: "0.5px solid #444",
                          textWrap: "nowrap",
                        }}
                      >
                        <img
                          src="https://assets.gofloaters.com/frequently.png"
                          style={{ width: "15px", height: "15px" }}
                          alt="Frequently Booked"
                        />{" "}
                        Frequently Booked
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {props.spacetype != "Cafe" ? (
                    <h2
                      title={
                        props.spacetype != "Cafe"
                          ? props.spaceDisplayName + " | "
                          : ""
                      }
                    >
                      {props.spacetype != "Cafe"
                        ? props.spaceDisplayName + " | "
                        : ""}
                      {props.spaceAddress}
                      {/*                    
                      {props.Rating ? (
                        <span
                          className={
                            "stars-container stars-" +
                            Number(20) * Number(props.Rating)
                          }
                        >
                          ★★★★★
                        </span>
                      ) : (
                        ""
                      )} */}
                    </h2>
                  ) : (
                    ""
                  )}
                  {props.spacetype === "Cafe" ? (
                    <h2 title={props.gftitle - props.OriginalName}>
                      {props.title}{" "}
                    </h2>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="col-3 text-right">
               
              </div> */}

                <div className="col-12">
                  <p className="spaceaddress">
                    {props.OriginalName}
                    {" - "}
                    {props.gftitle}
                    <span className="DesktopOnly">{" | "}</span>
                    <a
                      href={
                        "https://maps.google.com/?q=" +
                        props.geoLocation[0] +
                        "," +
                        props.geoLocation[1]
                      }
                      className="DesktopOnly"
                      target="_blank"
                      style={{ color: "#029eff" }}
                    >
                      Show on map
                    </a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 featureIcons">
                  {props.Facility.search("Metro Nearby") > 1 ? (
                    <div className="text-center featureIconBox">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/metro_nearby.svg"
                        alt="Hi Speed WiFi"
                        style={{
                          width: "22px",
                        }}
                      ></img>
                      <span>Metro Nearby</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.Facility.search("Hi Speed WiFi") > 1 ? (
                    <div className="text-center featureIconBox">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/hi_speed_wifi.svg"
                        alt="Hi Speed WiFi"
                        style={{
                          width: "22px",
                        }}
                      ></img>
                      <span>Hi Speed WiFi</span>
                    </div>
                  ) : (
                    ""
                  )}

                  {props.Facility.search("Whiteboard") > 1 ? (
                    <div className="text-center featureIconBox">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/white_board.svg"
                        alt="Whiteboard"
                        style={{
                          width: "22px",
                        }}
                      ></img>
                      <span>Whiteboard</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.Facility.search("Projector") > 1 ? (
                    <div className="text-center featureIconBox">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/projectors.svg"
                        alt="Projector"
                        style={{
                          width: "22px",
                        }}
                      ></img>
                      <span>Projector</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.Facility.search("Television") > 1 ? (
                    <div className="text-center featureIconBox">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/television.svg"
                        alt="Television"
                        style={{
                          width: "22px",
                        }}
                      ></img>
                      <span>Television</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*                  
                  {props.Facility.search("Free Parking") > 1 ? (
                    <div className="text-center featureIconBox">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/free_parking.svg"
                        alt="Free Parking"
                        style={{
                          width: "22px",
 
                        }}
                      ></img>
                      <span>Free Parking</span>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>
                    <b style={{ fontSize: "12px" }}>Suited for: </b>
                    {SpaceSuitedFor}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p style={{ fontSize: "12px" }}>
                    {/* {props.Facility.search("Metro Nearby") > 1 ? (
                      <b
                        style={{
                          color: "#303030",
                          textWrap: "nowrap",
                        }}
                      >
                        Nearby Metro
                      </b>
                    ) : (
                      ""
                    )} */}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  {props.hourPassAvailable && props.priceHr !== " " ? (
                    <HourPrice />
                  ) : (
                    ""
                  )}
                  {props.hourPassAvailable && props.dayPassAvailable
                    ? " | "
                    : ""}
                  {props.dayPassAvailable && props.priceDay !== " " ? (
                    <DailyPrice />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 meetingcardButtonNew cardMobileNoPadding">
            <div className="row DesktopOnly">
              <div className="col-12 text-right">
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {ratingText(props.Rating)}
                  <span style={{ display: "block", fontSize: "10px" }}>
                    {Number(props.googleReviewCount) +
                      Number(props.spaceFeedback.length)}{" "}
                    reviews
                  </span>
                </p>
              </div>
              {/* <div className="col-3 text-right">
                  {" "}
                  <span className="ratingBubble">{props.Rating}</span>
                </div> */}
            </div>

            {/* <div className="row MobileOnly">
              <div className="col-2">
                <span className="ratingBubble">{props.Rating}</span>
              </div>
              <div className="col-8 text-left">
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {ratingText(props.Rating)}
                  <span style={{ display: "block", fontSize: "10px" }}>
                    {Number(props.googleReviewCount) +
                      Number(props.spaceFeedback.length)}{" "}
                    reviews
                  </span>
                </p>
              </div>
            </div> */}

            <div
              className="text-right DesktopOnly"
              style={{ paddingBottom: "10px" }}
            >
              {props.bookingcount > 10 ? (
                <span
                  style={{
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    color: "#303030",
                    background: "#f8f8f8",
                    textWrap: "nowrap",
                    border: "0.5px solid #444",
                  }}
                >
                  <img
                    src="https://assets.gofloaters.com/popular.png"
                    style={{ width: "15px", height: "15px" }}
                    alt="Popular"
                  />{" "}
                  Popular
                </span>
              ) : (
                ""
              )}

              {props.bookingcount > 30 ? (
                <span
                  style={{
                    padding: "5px 10px",
                    borderRadius: "5px",
                    marginLeft: "10px",
                    fontSize: "12px",
                    color: "#303030",
                    background: "#f8f8f8",
                    textWrap: "nowrap",
                    border: "0.5px solid #444",
                  }}
                >
                  <img
                    src="https://assets.gofloaters.com/frequently.png"
                    style={{ width: "15px", height: "15px" }}
                    alt="Frequently Booked"
                  />{" "}
                  Frequently Booked
                </span>
              ) : (
                ""
              )}
            </div>

            {props.online ? (
              <Link to={props.Slug}>View Details</Link>
            ) : (
              <a href={props.Slug + "/"} target="_blank">
                Currently unavailable
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default MeetingListingCard
